<template>
  <el-form v-if="isShow">
    <!-- 功率控制 -->
    <electric-box-item
      v-if="isdialog.innerVisible"
      :isShow="isdialog.innerVisible"
      :panelForm="panelForm"
      :stateData="stateData"
      @close="isdialog.innerVisible = false"
    ></electric-box-item>

    <!-- 漏电自检控制 -->
    <leak-age
      v-if="isdialog.isLeakage"
      :isShow="isdialog.isLeakage"
      :panelForm="panelForm"
      :stateData="stateData"
      @close="isdialog.isLeakage = false"
    ></leak-age>
    <!-- 修改设定值 -->
    <chare-value
      v-if="isdialog.isSetValue"
      :isShow="isdialog.isSetValue"
      @close="isdialog.isSetValue = false"></chare-value>
    <!-- 其它dialog -->
    <v-dia-log
      v-if="isdialog.dialog"
      :isShow="isdialog.dialog"
      :panelForm="panelForm"
      :stateData="stateData"
      @close="isdialog.dialog = false"
      :type="item"
    ></v-dia-log>
     <!-- v-if="panelForm.devSecondType == 52" -->
    <template>
      <!-- <p>MD智能电箱</p> -->
      <!-- <div class="wrapper">
        <div class="button" v-for="(item, index) in 5" :key="index">
          <div id="electricBoxPower" class="variable">--</div>
          <div class="explain">{{type1(item)}}</div>
        </div>
      </div> -->
      <div class="wrapper">
        <div class="button">
          <div id="electricBoxPower" class="variable">{{state.aw}}</div>
          <div class="explain">功率/W</div>
        </div>
        <div class="button">
          <div id="electricBoxPower" class="variable">{{state.aa}}</div>
          <div class="explain">电流/mA</div>
        </div>
        <div class="button">
          <div id="electricBoxPower" class="variable">{{state.av}}</div>
          <div class="explain">电压/V</div>
        </div>
        <div class="button">
          <div id="electricBoxPower" class="variable">{{state.at}}</div>
          <div class="explain">温度/c</div>
        </div>
        <div class="button">
          <div id="electricBoxPower" class="variable">{{state.power}}</div>
          <div class="explain">总电量/Kw.H</div>
        </div>
      </div>
      <div class="wrapper">
        <div class="button" @click="boxShow(0)">
          <div id="electricBoxPower" class="variable">{{state.mxgg}}</div>
          <div class="explain">限定功率/W</div>
        </div>
        <div class="button" @click="boxShow(1)">
          <div id="electricBoxPower" class="variable">{{state.mxgl}}</div>
          <div class="explain">限定电流/A</div>
        </div>
        <div class="displayArea" @click="boxShow(2)">
          <div class="outside3">
            <div class="inside3" :style="{'background-color': centerButtonColor}" id="controllInside">
              <div class="content">
                <div class="tips" id="electrifiedState">{{stateInformation}}</div>
                <div class="shownum">当前状态</div>
              </div>
            </div>
          </div>
        </div>
        <div class="button" @click="boxShow(3)">
          <div id="electricBoxPower" class="variable">{{state.ald}}</div>
          <div class="explain">漏电流</div>
        </div>
        <div class="button" @click="boxShow(4)">
          <div id="electricBoxPower" class="variable">{{enableCheck}}</div>
          <div class="explain">漏电自检</div>
        </div>
        <!-- <template v-for="(item, index) in 5" >
          <div :class="index === 2 ? 'displayArea':'button'" :key="index" 
          @click="boxShow(index)">
            <div id="electricBoxPower" class="variable" v-show="index !== 2">--</div>
            <div class="explain" v-show="index !== 2">{{type2(item)}}</div>
            <div class="outside3" v-show="index === 2">
              <div class="inside3" id="controllInside">
                <div class="content">
                  <div class="tips" id="electrifiedState">未知</div>
                  <div class="shownum">当前状态</div>
                </div>
              </div>
            </div>
          </div>
        </template> -->
      </div>
      <el-row>
        <el-col>
          <p style="text-align:center">
            更新时间：{{time}}
          </p>
        </el-col>
      </el-row>
      <el-row type="flex" justify="end" v-if="panelForm.devSecondType == 52" style="text-align:right">
          <img
            src="@/assets/img/giveAnAlarm.png"
            width="38"
            @click="show(1)"
            class="img"
          />
          <img
            src="@/assets/img/WIFI.png"
            width="38"
            @click="show(2)"
            class="img"
          />
          <img
            src="@/assets/img/details.png"
            width="38"
            @click="show(3)"
            class="img"
          />
          <img
            src="@/assets/img/modify.png"
            width="38"
            @click="show(4)"
            class="img"
          />
      </el-row>
    </template>
  </el-form>
</template>
<script>
const TYPE1 = ["功率/W", "电流/mA", "电压/V", "温度/c", "总电量/Kw.H"];
const TYPE2 = ["限定功率/W", "限定电流/A", "当前状态", "漏电流", "漏电自检"];
import VDiaLog from "./Dialog/index";
import electricBoxItem from "./ElectricControl/boxItem";
import LeakAge from "./ElectricControl/Leakage";
import ChareValue from './ElectricControl/chargeValue'
import moment from 'moment';
export default {
  data() {
    return {
      isBox: false,
      isdialog: {
        innerVisible: false,
        isLeakage: false,
        dialog: false,
        isSetValue: false,
      },
      interval: null,
      time: '',
      item: null, //其它dialog类型
      state: {
        aw: '--',//功率
        aa: '--',//电流
        av: '--',//电压
        at: '--',//温度
        power: '--',//总电量
        mxgg: '--',//限定功率
        mxgl: '--',//限定电流
        ald: '--',//漏电流
        enableCheck: '--',//漏电自检
      },
      stateInformation: '未知',//当前状态
      enableCheck: '--',
      centerButtonColor: '#B4B4B4',
      stateData: {},
    };
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    panelForm: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch:{
    isShow() {
      console.log(this.isShow)
    },
  },
  created() {
    this.getAllStateB()
    console.log(this.panelForm)
  },
  mounted() {
    this.interval = setInterval(() => {
      this.getAllStateB()
      this.time = moment().format('YYYY-MM-DD HH:mm:ss')
    }, 1000)
  },
  beforeDestroy(){
    clearInterval(this.interval)
  },
  methods: {
    // 遍历表格类型
    // type1(item) {
    //   return TYPE1[item - 1];
    // },
    // type2(item) {
    //   return TYPE2[item - 1];
    // },
    boxShow(index) { //控制不同dialog的显示（包括不同型号电箱）
      if(this.panelForm.devSecondType !== 52) { // 不属于电箱 / 52(52 为二级型号中一中)
        if(index === 2) {
        } else {
          this.isdialog.isSetValue = true
        }
        return
      }
      if (index === 0 || index === 1) {
        this.isdialog.innerVisible = true;
      } else if (index === 2) {
        if (this.stateInformation != '离线') {
          let state = this.stateInformation == '通电' ? 'close' : 'open'
          this.$axios({
            method: 'post',
            url: `/common2/devices/operate`,
            data: {
              type: 'OCSWITCH',
              devId: this.panelForm.jourDeviceId,
              devState: state
            }
          }).then(res=>{
            this.$message.success('控制成功')
          }).catch(err=>{
            this.isError(err,this)
          })
        } else {
          return
        }
      } else if (index === 3) {
        return;
      } else if (index === 4) {
        this.isdialog.isLeakage = true;
      }
    },
    show(idx) {
      //展示其它dialog
      // console.log(idx)
      this.item = idx; //不同dialog根据idx展示
      this.isdialog.dialog = true;
      console.log(this.isdialog.dialog);
    },
    // 电箱控制
    isElectricBoxControl(item) {},
    getAllStateB(index, ids){//获取所有设备状态
      this.$axios({
        method: 'get',
        // url: `/common2/devices/getBatchState`,
        url: `/common2/devices/getDevicesState`,
        params: {
          ids: this.panelForm.jourDeviceId
        }
      }).then(res=>{
        console.log(res.data.result[0])
        this.enableCheck = res.data.result[0].enableCheck ? '已启用' : '未启用'
        this.stateInformation = res.data.result[0].stateInformation == '离线' ? '离线' : res.data.result[0].oc ? '通电' : '断电'
        this.centerButtonColor = res.data.result[0].stateInformation == '离线' ? '#B4B4B4' : res.data.result[0].oc ? '#65b72d' : '#B4B4B4'
        // this.centerButtonColor = res.data.result[0].stateInformation == '通电' ? '#65b72d' : '#B4B4B4'
        for (let i in res.data.result[0]) {
          for (let j in this.state) {
            if (i == j) {
              this.state[j] = res.data.result[0][i]
            }
          }
        }
        this.stateData = res.data.result[0]
        console.log(this.stateData)
      }).catch(err=>{
        this.isError(err,this)
      })
    }
  },
  components: {
    electricBoxItem,
    LeakAge,
    VDiaLog,
    ChareValue
  },
};
</script>

<style lang="scss" scoped>
.circle > ::before {
  content: "";
  display: block;
  position: absolute;
  width: 150px;
  height: 150px;
  border-radius: 50%; /**控制形状**/
  background-color: #000; /**控制图标颜色**/
  left: 50%; //居中
  transform: translate(-50%, 0);
}
.bgcolor {
  background: #d3dce6;
}
.img {
  width: 50px;
  height: 50px;;
  padding: 0 8px;
}
.wrapper {
  display: flex;
  justify-content: space-around;
  .variable {
    font-size: 30px;
    font-family: "ds-digitalbold";
    color: #65b72d;
    margin-top: 2px;
    text-align: center;
    line-height: 46px;
  }
  .explain {
    font-size: 18px;
    color: #b4b4b4;
    margin-top: 1px;
    text-align: center;
  }
}
.displayArea{
  text-align: center;
  margin: 20px 14px 0 20px;
  cursor: pointer;
  .outside3 {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    margin: auto;
    background-color: #EDF4FF;
    padding-top: 5px;
    pointer-events:none;
    .inside3 {
      width: 115px;
      height: 120px;
      margin: auto;
      padding-top: 20px;
      border-radius: 50%;
      box-sizing: border-box;
      .tips {
        height: 35px;
        margin: auto;
        color: white;
        font-size: 42px;
      }
      .shownum {
        height: 30px;
        line-height: 30px;
        color: white;
        margin: auto;
        font-size: 18px;
        margin-top: 15px;
      }
    }
  }
}
.button {
  background-color: #edf4ff;
  width: 150px;
  height: 80px;
  border-radius: 7px;
  float: left;
  margin: 0 0 10px 10px;
}
</style>
