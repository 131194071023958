<template>
  <div>
    <el-date-picker
      v-model="timeArr"
      type="datetimerange"
      format="yyyy-MM-dd HH:mm:ss"
      value-format="yyyy-MM-dd HH:mm:ss"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      @change="dateChange">
    </el-date-picker>
    <el-table
      :data="tableData"
      style="width: 100%;min-height:400px">
      <el-table-column prop="id" label="记录id"></el-table-column>
      <el-table-column prop="address" label="设备线路地址"></el-table-column>
      <el-table-column prop="name" label="线路名称"></el-table-column>
      <el-table-column prop="type" label="告警类型"></el-table-column>
      <el-table-column prop="time" label="告警时间"></el-table-column>
      <el-table-column prop="content" label="告警内容"></el-table-column>
    </el-table>
    <el-pagination
      small
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[5,10,20,40,100]"
      :page-size="params.limit"
      :current-page="params.cursor"
      :page-count="params.pages"
      :total="params.total"
      align="center"
      background
      @current-change="cursorChange2"
      @size-change="limitChange"
      style="text-align:center"
    ></el-pagination>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: 'AlarmRecord',
  props: {
    panelForm: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data () {
    return {
      timeArr:null,
      params: {
        startTime: '',
        endTime: '',
        limit: 10,
        cursor: 1,
        total: 0,
        pages: 0,
      },
      tableData:[],
    }
  },
  created() {
    let start = moment().format('YYYY-MM-DD 00:00:00')
    let end = moment().format('YYYY-MM-DD HH:mm:ss')
    this.timeArr = [start,end]
    this.params.startTime = this.timeArr[0]
    this.params.endTime = this.timeArr[1]
    this.getData()
  },
  methods: {
    getData() {
      this.$axios({
        method: 'get',
        url: `/common2/devices/${this.panelForm.jourDeviceId}/getPageBoxAlarm`,
        params: this.params
      }).then(res=>{
        this.$message.success('查询成功')
      }).catch(err=>{
        this.isError(err,this)
      })
    },
    dateChange() {
      console.log(this.timeArr)
      this.params.startTime = this.timeArr[0]
      this.params.endTime = this.timeArr[1]
      this.getData()
    },
    cursorChange2(val) {
      this.params.cursor = val;
      this.getData();
    },
    limitChange(val) {
      this.params.limit = val;
      this.getData();
    },
  },
}
</script>

<style>

</style>