<template>
  <el-dialog v-dialogDrag
    width="500px"
    title="修改设定值"
    append-to-body
    :visible.sync="show"
    class="item"
    :close-on-click-modal="false"
  >
    <el-form
      :label-position="labelPosition"
      label-width="150px"
      :model="formLabelAlign"
    >
      <el-form-item label="限定电量" style="padding-bottom:20px">
        <el-input v-model="formLabelAlign.name" size="mini"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >提交</el-button
        >
        <el-button type="danger" @click="show = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
const TYPES = ["power", "Leakage"];
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    types: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      show: this.isShow,
      labelPosition: "left",
      formLabelAlign: {
        //功率
        name: "",
        region: "",
        type: "",
      },
    };
  },
  watch: {
    show(n, o) {
      this.$emit("close");
    },
    methods: {
      resetForm() {
        this.$emit("close");
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  >>> .el-switch__core,
  .el-switch .el-switch__label {
    width: auto;
  }
}
</style>
