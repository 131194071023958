<template>
  <el-dialog v-dialogDrag
    :width="type % 2 === 0 ? '600px': '1000px'"
    :title="title"
    append-to-body
    :visible.sync="show"
    class="item"
    :close-on-click-modal="false"
  >
    <alarm-record v-if="type === 1" :panelForm="panelForm"></alarm-record>
    <charge-wifi v-else-if="type === 2" :panelForm="panelForm" ref="wifi"></charge-wifi>
    <detail v-else-if="type === 3" :stateData="stateData"></detail>
    <charge-name v-else-if="type === 4" ref="name"></charge-name>
    <div v-if="type % 2 === 0" style="text-align:center;padding-bottom:20px;padding-top:30px">
      <el-button type="primary" @click="determine">确定</el-button>
      <el-button type="danger" @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import AlarmRecord from './components/AlarmRecord'
import ChargeWifi from './components/chargewifi'
import Detail from './components/Detal'
import ChargeName from './components/ChargeName'
const TYPES = ['power','Leakage']
export default {
  name: 'Dialog',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    panelForm: {
      type: Object,
      default() {
        return {};
      },
    },
    stateData: {
      type: Object,
      default() {
        return {};
      },
    },
    type: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      title: '电箱控制',
      show: this.isShow,
      labelPosition: 'left',
    }
  },
  watch:{
    show(n,o) {
      this.$emit('close')
    },
  },
  created() {
    console.log(this.stateData)
    this.title = this.type == 1 ? '电箱告警记录' : this.type == 2 ? '网络设置' : this.type == 3 ? '详细参数' : '线路名称修改'
    this.stateData.oc = this.stateData.oc ? '开' : '关'
    this.stateData.enableNetCtrl = this.stateData.enableNetCtrl ? '允许' : '不允许'
    this.stateData.validity = this.stateData.validity ? '有效' : '无效'
    this.stateData.online = this.stateData.online ? '在线' : '离线'
    this.stateData.lineType = this.stateData.lineType == '220' ? '告警取消' : '存在告警'
    this.stateData.alarm = this.stateData.alarm == 0 || 128 ? '允许' : '不允许'
    this.stateData.gatherAddr = this.stateData.gatherAddr == -1 ? '进线直连' : this.stateData.gatherAddr
    this.stateData.control = this.stateData.control ? '是' : '否'
    this.stateData.visibility = this.stateData.visibility ? '是' : '否'
    this.stateData.mainLine = this.stateData.mainLine == '0' ? '非总线' : '总线'
  },
  methods: {
    determine() { //确定
      let refname = this.type === 2 ? 'wifi':'name'
      this.$refs[refname].charge()
    },
    close() {
      let refname = this.type === 2 ? 'wifi':'name'
      this.$refs[refname].close()
      this.show = false
    },
  },
  components: {
    AlarmRecord,
    ChargeWifi,
    Detail,
    ChargeName
  }
};
</script>

<style lang="scss" scoped>
.item {
  >>> .el-switch__core, .el-switch .el-switch__label{
    width: auto;
  }
}
</style>
