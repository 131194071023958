<template>
  <el-dialog v-dialogDrag
    width="600px"
    title="电箱控制"
    append-to-body
    :visible.sync="show"
    class="item"
    :close-on-click-modal="false"
    @close="dialogClose"
  >
    <label for="open"
    >启用漏电自检</label>
    <!-- <el-switch
      v-model="Leakage.opencheck"
      active-text="开"
      inactive-text="关"
      id="open">
    </el-switch> -->
    <el-switch
      v-model="Leakage.opencheck"
      active-text="开"
      inactive-text="关"
      class="switchStyle"
      style="padding:10px"
      id="open"
    ></el-switch>
    <el-form
      :label-position="labelPosition"
      label-width="auto"
      :model="Leakage"
      :rules="rules"
      ref="Leakage"
    >
      <!-- <el-form-item label="日">
        <el-date-picker
          v-model="Leakage.day"
          type="date"
          placeholder="选择日期时间"
          size="mini"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="时">
        <el-time-picker v-model="Leakage.time" placeholder="任意时间点"
        size="mini">
        </el-time-picker>
      </el-form-item> -->
      <el-form-item label="自检日期" prop="day">
        <el-select v-model="Leakage.day" placeholder="选择日期时间" size="mini" clearable style="width:120px">
          <el-option v-for="(item,i) in day" :key="i" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="时" prop="hour">
        <el-select v-model="Leakage.hour" placeholder="选择小时" size="mini" clearable style="width:120px">
          <el-option v-for="(item,i) in hour" :key="i" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="分" prop="minute">
        <el-select v-model="Leakage.minute" placeholder="选择分钟" size="mini" clearable style="width:120px">
          <el-option v-for="(item,i) in minute" :key="i" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="注">
        <p>该功能启用后，在执行时会导致该线路短暂停电，建议设置时间为07:00。</p>
        <p>当前漏电自检功能，上次自检时间为：{{stateData.lastCheckDate}}</p>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit">提交</el-button>
        <el-button type="danger" @click="show = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
const TYPES = ["power", "Leakage"];
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    panelForm: {
      type: Object,
      default() {
        return {};
      },
    },
    stateData: {
      type: Object,
      default() {
        return {};
      },
    },
    types: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      show: this.isShow,
      labelPosition: "left",
      day: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28],
      hour: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23],
      minute: [0,5,10,15,20,25,30,35,40,45,50,55],
      rules:{//表单验证
        day:[{ required: true, message: '日不能为空', trigger: 'change'}],
        hour:[{ required: true, message: '时不能为空', trigger: 'change'}],
        minute:[{ required: true, message: '分不能为空', trigger: 'change'}],
      },
      Leakage: {
        //漏电自检设置
        opencheck: false,
        day: null,
        hour: null,
        minute: null,
      },
    };
  },
  watch: {
    show(n, o) {
      this.$emit("close");
    },
  },
  created() {
    this.Leakage.opencheck = this.stateData.enableCheck == '已启用' ? true : false
    let str = this.stateData.checkDateCfg.split(',')
    console.log(str)
    this.Leakage.day = Number(str[0]) != 0 ? Number(str[0]) : null
    this.Leakage.hour = Number(str[1])
    this.Leakage.minute = Number(str[2])
  },
  methods: {
    submit() {
      if (!this.submitForm(['Leakage'], this)){
        return
      }
      this.$axios({
        method: 'post',
        url: `/common2/devices/operate`,
        data: {
          type: 'SETAUTOLEAK',
          devId: this.panelForm.jourDeviceId,
          lineName: this.formLabelAlign.name,
          maximumPower: this.formLabelAlign.region,
          overflowThreshold: this.formLabelAlign.type,
          enableCheck: this.Leakage.opencheck,
          day: this.Leakage.day,
          hour: this.Leakage.hour,
          minute: this.Leakage.minute
        }
      }).then(res=>{
        this.$message.success('修改成功')
      }).catch(err=>{
        this.isError(err,this)
      })
    },
    dialogClose() {
      this.resetForm(['Leakage'], this);
      this.Leakage = {
        opencheck: false,
        day: null,
        hour: null,
        minute: null,
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
