<template>
  <el-form label-width="80px">
    <el-form-item label="支路名称">
      <el-input v-model="value" placeholder="请输入支路名称"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  data () {
    return {
      value: ''
    }
  },
  methods: {
    charge() {
      if (this.value == ''){
        this.$message.error('支路名称不能为空')
        return
      }
      this.$axios({
        method: 'put',
        url: `/common2/devices/${this.panelForm.jourDeviceId}`,
        data: {
          devNickname: this.value
        }
      }).then(res=>{
        this.$message.success('修改成功')
      }).catch(err=>{
        this.isError(err,this)
      })
    },
    close() {
      this.value = ''
    },
  }
}
</script>

<style>

</style>