<template>
  <el-form 
  :model="wifi"
  :rules="rules"
  ref="wifi" 
  label-width="100px">
    <el-form-item label="原WiFi名称" prop="oldName">
      <el-input v-model="wifi.oldName"></el-input>
    </el-form-item>
    <el-form-item label="原WiFi密码" prop="oldPassword">
      <el-input v-model="wifi.oldPassword"></el-input>
    </el-form-item>
    <el-form-item label="新WiFi名称" prop="newName">
      <el-input v-model="wifi.newName"></el-input>
    </el-form-item>
    <el-form-item label="新WiFi密码" prop="newPassword">
      <el-input v-model="wifi.newPassword"></el-input>
    </el-form-item>
    <p>注：WiFi名称只能为英文或数字组成，不能为中文名称。</p>
  </el-form>
</template>

<script>
export default {
  name: 'ChargeWifi',
  props: {
    panelForm: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data () {
    return {
      rules:{//表单验证
        oldName:[{ required: true, message: '原WiFi名称不能为空', trigger: 'blur'}],
        oldPassword:[{ required: true, message: '原WiFi密码不能为空', trigger: 'blur'}],
        newName:[{ required: true, message: '新WiFi名称不能为空', trigger: 'blur'}],
        newPassword:[{ required: true, message: '新WiFi密码不能为空', trigger: 'blur'}],
      },
      wifi: {
        oldName: '',
        oldPassword: '',
        newName: '',
        newPassword: '',
      },
    }
  },
  methods: {
    charge() {
      if (!this.submitForm(['wifi'], this)){
        return
      }
      this.$axios({
        method: 'post',
        url: `/common2/devices/operate`,
        data: {
          type: 'SETWIRELESS',
          devId: this.panelForm.jourDeviceId,
          oldSsid: this.wifi.oldName,
          oldSidPassword: this.wifi.oldPassword,
          newSsid: this.wifi.newName,
          newSsidPassword: this.wifi.newPassword
        }
      }).then(res=>{
        this.$message.success('修改成功')
      }).catch(err=>{
        this.isError(err,this)
      })
    },
    close() {
      this.resetForm(['wifi'], this);
      this.wifi = {
        oldName: '',
        oldPassword: '',
        newName: '',
        newPassword: '',
      }
    },
  }
}
</script>

<style>

</style>