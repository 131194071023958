<template>
  <el-form
    v-model="detail"
    label-width="120px"
    style="display:flex;flex-wrap: wrap;"
    v-loading="loading"
  >
    <el-form-item :label="item.label" v-for="(item, index) in types" :key="index">
      <el-input v-model="item.value" size="mini" readonly></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
const AA = 1
export default {
  props: {
    stateData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      detail: {},
      qwe: '',
      loading: false, //表格遮罩层
      types: [
        {label: '电箱编号',value: this.stateData.mac,},
        {label: '线路地址',value: this.stateData.addr,},
        {label: '线路名称',value: this.stateData.title,},
        {label: '线路有效性',value: this.stateData.validity,},
        {label: '线路开关状态',value: this.stateData.oc,},
        {label: '远程控制合闸分闸',value: this.stateData.enableNetCtrl,},
        {label: '在线状态',value: this.stateData.stateInformation,},
        {label: '当前电量',value: this.stateData.power,},
        {label: '过功门限值',value: this.stateData.mxgg,},
        {label: '过流门限值',value: this.stateData.mxgl,},
        {label: '类型',value: this.stateData.lineType,},
        {label: '告警状态',value: this.stateData.alarm,},
        {label: '线路规格',value: this.stateData.specification,},
        {label: '控制标记',value: this.stateData.control,},
        {label: '显示标志',value: this.stateData.visibility,},
        {label: '总线标记',value: this.stateData.mainLine,},
        {label: '漏电流',value: this.stateData.ald,},
        {label: '电流',value: this.stateData.aa,},
        {label: '温度',value: this.stateData.at,},
        {label: '电压',value: this.stateData.av,},
        {label: '功率',value: this.stateData.aw,},
        {label: '功率因素',value: this.stateData.apf,},
        {label: '平均电流',value: this.stateData.ga,},
        {label: '壳温度',value: this.stateData.gt,},
        {label: '平均电压',value: this.stateData.gv,},
        {label: '功率和值',value: this.stateData.gw,},
        {label: '更新时间',value: this.stateData.updateTime,},
      ],
    };
  },
  created() {
    console.log(this.stateData)
  },
  methods: {
    determine() { //确定
      // let refname = this.type === 2 ? 'wifi':'name'
      // this.$refs[refname].charge()
    },
  },
};
</script>

<style></style>
